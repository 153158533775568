/* Стиль для всего приложения */
.App
{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #001529;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Стили для формы */
form
{
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #0a2c48;
}

/* Белый цвет для заголовка */
h2
{
  text-align: center;
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 30px
}

/* Стили для меток */
label
{
  margin-top: 10px;
  font-size: 14px;
  color: #ffffff;
}

/* Стили для полей ввода */
input
{
  padding: 10px;
  margin: 15px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Стили для кнопки */
button
{
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

button:hover
{
  background-color: #0056b3;
}

/* Цвета для сообщений */
.message
{
  text-align: center;
  margin-top: 10px;
  font-size: 18px
}

.success
{
  color: #00FF00;
}

.error
{
  color: #FFFF00;
}