.section-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.tile-grid
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
    padding: 10px;
    max-width: 100%;
}

@media (max-width: 768px)
{
    .tile-grid
    {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 576px)
{
    .tile-grid
    {
        grid-template-columns: repeat(2, 1fr);
    }
}