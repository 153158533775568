.icon-container {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
}

/* Основной контейнер */
.tasks-container {
    margin-top: 25px;
    padding: 20px;
    width: 100%;
    background-color: #0a2c48;
    color: #ffffff;
}

/* Кнопка добавить */
.add-button {
    position: absolute;
    right: 20px;
    top: 65px;
    margin-bottom: 10px;
    background-color: #007bff;
    border: none;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease;
}

.add-button:hover {
    background-color: #0056b3;
}

/* Таблица */
.tasks-table .ant-table {
    background-color: #001f3f;
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0 50px;
}

.tasks-table .ant-table-thead > tr > th {
    background-color: #2255ff;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 7px;
    font-size: 15px;
    border-right: none;
    border-bottom: 10px solid #0a2c48;
}

.tasks-table .ant-table-placeholder {
    background-color: #002244;
    color: #ffffff;
}

.tasks-table .ant-table-tbody > tr {
    margin-bottom: 10px;
    border-radius: 8px;
}

.tasks-table .ant-table-tbody > tr > td {
    padding: 15px;
    background-color: #004a9f;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    border-bottom: none;
}

.tasks-table .ant-table-tbody > tr > td img {
    object-fit: cover;
    border-radius: 8px;
    border: 0px solid #ffffff;
    width: 50px;
    height: 50px;
}

.tasks-table .ant-table-tbody > tr:hover > td {
    background-color: #004488 !important;
    transition: background-color 0.3s ease;
}

/* Кнопки действия */
.edit-button {
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    transition: background-color 0.3s ease;
    margin-right: 15px;
}

.edit-button:hover {
    background-color: #388e3c;
    color: #388e3c;
}

.delete-button {
    background-color: #f44336;
    color: #ffffff;
    border: none;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #d32f2f;
    color: #d32f2f;
}

/* Модалка редактирования */
.custom-modal .ant-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #001f3f;
    box-shadow: none;
}

.custom-modal .ant-modal-content {
    background-color: #001f3f;
    margin-left: 110px;
    margin-top: 10px;
    color: #ffffff;
    width: 100%;
    max-width: 300px;
    box-shadow: true;
}

.custom-modal .ant-modal-body {
    display: flex;
    flex-direction: column;
    background-color: #001f3f;
    gap: 0px;
    padding: 5px;
    margin-top: 35px;
    border: none;
}

/* Форма с полями внутри модалки */
.modal-form {
    width: 100%;
    height: 10%;
}

.custom-modal .ant-modal-header {
    background-color: transparent;
    border-bottom: none;
}

.custom-modal .ant-modal-footer {
    background-color: transparent;
    border-top: none;
}

.custom-modal .ant-form-item {
    width: 100%;
    padding: 5px;
    background-color: #0a2c48;
    margin-bottom: 16px;
}

.custom-modal .ant-input {
    width: 100%;
    padding: 5px;
    font-size: 18px;
    border: 0.5px solid #0056b3;
    background-color: #003366;
    color: #ffffff;
}

.custom-modal .ant-input::placeholder {
    color: #ffffff;
}

.custom-modal .ant-input:focus,
.custom-modal .ant-input:hover {
    background-color: #003366;
    border: 1px solid #007bff;
}

.custom-modal .ant-form-item-has-error .ant-input {
    border-color: #ff4d4f;
    background-color: #003366;
}

.custom-modal .ant-form-item-has-error .ant-form-item-explain {
    color: #ff4d4f;
}

.custom-modal .ant-modal-close {
    color: #ffffff;
}

.custom-modal .ant-form-item-label > label {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
}

.custom-modal .ant-upload-list-item-name {
    color: #ffffff;
    font-weight: bold;
}

.custom-modal .ant-upload-list-item-action {
    color: #ffffff;
}

.custom-modal .ant-upload-list-item-action:hover {
    color: #d3d3d3;
}

.modal-title {
    font-size: 22px;
    margin-left: 8px;
    margin-top: -8px;
    position: absolute;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
}

.form-item label {
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
}

.loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
}

@media (max-width: 768px)
{
    .tasks-container
    {
        padding: 10px;
    }

    .tasks-table .ant-table
    {
        font-size: 12px;
    }

    .tasks-table .ant-table-thead > tr > th,
    .tasks-table .ant-table-tbody > tr > td {
        padding: 8px;
    }

    .add-button {
        right: 10px;
        top: 55px;
        padding: 8px;
        font-size: 15px;
    }

    .custom-modal .ant-modal-content {
        margin-left: 80px;
        margin-top: 5px;
    }
}

@media  (max-width: 480px)
{
    .tasks-container
    {
        padding: 10px;
    }

    .tasks-table .ant-table
    {
        font-size: 12px;
    }

    .tasks-table .ant-table-thead > tr > th,
    .tasks-table .ant-table-tbody > tr > td {
        padding: 5px;
        font-size: 7px;
    }

    .edit-button,
    .delete-button {
        width: 20px;
        height: 20px;
        font-size: 12px;
        padding: 4px;
        margin: 2px;
    }

    .custom-modal .ant-modal-content {
        margin-left: 20px;
        margin-top: 20px;
    }
}