.dashboard-layout
{
    min-height: 100vh;
}

.menu-header
{
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
}

.header
{
    background-color: #0a2c48;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    position: relative;
}

.burger-menu-button
{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-menu .ant-menu-item
{
    background-color: #001529;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
}

.sider-collapsed
{
    display: none;
}

.sider-expanded
{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #001529;
    width: 250px;
}

.content-container
{
    padding: 0px;
    background-color: #0a2c48;
}

@media (max-width: 760px)
{
    .header h1
    {
        font-size: 24px;
    }
}

@media (max-width: 576px)
{
    .header h1
    {
        font-size: 20px;
    }
}