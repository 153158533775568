.daily-combo-container
{
    color: #ffffff;
    text-align: center;
    max-width: 600px;
    margin: 10px auto;
}

.section-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.viruses-header
{
    font-size: 2rem;
}

.tile-grid
{
    display: grid;
    gap: 10px;
    justify-content: center;
    padding: 15px;
    max-width: 100%;
}

.tile-display
{
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

.combo-tile-image
{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.flip-clock
{
    display: flex;
    gap: 10px;
}

.flip-clock-card
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight: bold;
    background: #fff;
    color: #007bff;
    padding: 0px;
    border-radius: 5px;
    width: 90px;
    height: 90px;
    text-align: center;
}

@media (max-width: 768px)
{
    .viruses-header
    {
        font-size: 1.5rem;
    }
    .flip-clock-card
    {
        width: 80px;
        height: 80px;
        font-size: 3rem;
        padding: 3px;
    }
    .tile-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 576px)
{
    .viruses-header
    {
        font-size: 1.2rem;
    }
    .flip-clock-card
    {
        width: 60px;
        height: 60px;
        font-size: 2.5rem;
    }
    .tile-grid
    {
        grid-template-columns: repeat(4, 1fr);
    }
}